import React, { useState } from "react";

const useForm = (val) => {
    const [values, setValues] = useState(val);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (errors[name]) {
            delete errors[name];
        }
        setValues({ ...values, [name]: value });
    };

    const handleSubmit = (onSubmit) => (e) => {
        e.preventDefault();
        let error = validate(values);
        const errorArray = Object.keys(error);


        if (errorArray.length > 0) {
            setErrors(error);
            let i = 0;
            for (let key in error) {
                if (i == 0) {
                    alert(error[key]);
                }

                i++;
            }
        } else {
            if (onSubmit) onSubmit(values);
        }
    };

    const setValue = ({ name, value }) => {
        setValues({ ...values, [name]: value });
    };

    return { values, handleChange, handleSubmit, setValues, setValue, errors };
};

const validate = (values) => {
    const errors = {};

    if (!values.name) {
        errors.name = "Name is required";
    }

    if (!values.email) {
        errors.email = "Email is required";
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)) {
        errors.email = "Email is invalid";
    }

    if (!values.locationCode) {
        errors.locationCode = "Location is required";
    }

    if (!values.role) {
        errors.role = "Role is required";
    }

    if (!values.sapId) {
        errors.sapId = "SAP ID is required";
    }

    if (!values.whsCode) {
        errors.whsCode = "Warehouse is required";
    }

    if (values.password) {
        if (values.password != values.confirm) {
            errors.confirm = "Password confirmation is not match";
        }
    }

    return errors;
};

export default useForm;

import { Panel } from 'primereact/panel'
import { InputText } from 'primereact/inputtext';
import {Button} from 'primereact/button';
import React, { useState, useEffect } from 'react'
import { AuthService } from '../service/AuthService';
import {useForm} from 'react-hook-form'
import { classNames } from 'primereact/utils';
import axios from 'axios';
import { AppState } from '../App';

const authService = new AuthService();

export const Login = (props) => {

    const {register, handleSubmit, setValue, watch, formState: {errors}} = useForm();
    const [errorMessage, setErrorMessage] = useState("")

    useEffect(() => {

    }, [])

    const success = res => {
        const {onSuccess} = props;
        console.log('res',res);
        const {acessToken,refreshToken} = res.data;
        localStorage.setItem("accessToken", acessToken);
        localStorage.setItem("refreshToken", refreshToken);
        axios.defaults.headers['Authorization'] = `Bearer ${acessToken}`;
        if (onSuccess){
            onSuccess();
        }
    };

    const onLogin = (payload) => {

        setErrorMessage('');
        authService.login(payload, success, error => {
            const {data} = error.response;
            console.log('Error', data);
            setErrorMessage(data.error.message);
        });

    }

    return (
        <div className='lg:col-4 md:col-6 xs:col-12 sm:col-12' style={{ display: 'flex', margin: '0 auto', height: '100vh', alignItems: 'center', width: 500}}>
            <div className='col-12'>
                <Panel>
                    <h1 style={{ textAlign: 'center', color: '#F5A81C', fontWeight: 'bold', marginTop: 40 }}>MODENA - WMS </h1>
                    <h4 style={{ textAlign: 'center' }}>Warehouse Management System Lite</h4>
                    <form onSubmit={handleSubmit(onLogin)}>
                        <div style={{margin: '20px 40px', marginTop: 40}}>
                            <span className="p-input-icon-left" style={{ width: '100%', }}>
                                <i className="pi pi-user" />
                                <InputText {...register("userName", {required: true})} type="text" placeholder="Email" style={{ width: '100%' }}
                                className={classNames({'p-invalid': errors.userName})}/>
                            </span>
                            <span style={{display: 'block', color: 'red', marginTop: 5}}>{errors.userName?.type === 'required' && "User Name is required"}</span>
                        </div>
                        <div style={{margin: '20px 40px'}}>
                            <span className="p-input-icon-left" style={{ width: '100%', }}>
                                <i className="pi pi-lock" />
                                <InputText  {...register("password", {required: true})} type="password" placeholder="Password" style={{ width: '100%' }}
                                className={classNames({'p-invalid': errors.password})}/>
                            </span>
                            <span style={{display: 'block', color: 'red', marginTop: 5}}>{errors.password?.type === 'required' && "Password is required"}</span>
                        </div>
                        <div style={{margin: '0 40px'}}>
                            <span style={{color: 'red'}}>{errorMessage}</span>
                        </div>
                        <div style={{margin: '20px 40px', textAlign: 'center'}}>
                            <Button label="Login" className="p-button-raised p-button-warning mr-2 mb-2" style={{minWidth: 200, color: '#fff'}} type="submit"/>
                        </div>
                    </form>
                </Panel>
            </div>
        </div>
    )
}

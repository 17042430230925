import React, { useState, useRef, useEffect } from 'react'
import { Dialog } from "primereact/dialog";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { withSwalInstance } from 'sweetalert2-react';
import Swal from 'sweetalert2';
import { Card } from 'primereact/card';
import { UserService } from '../service/UserService';
import 'primeflex/primeflex.css';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from "primereact/dropdown";


const roleService = new UserService();
const state = {
    pageConfig: {
        page: 1,
        pageSize: 10,
        orderBy: "name",
        ascending: true,
        sortField: "id"
    },
    first: 0,
    rowsCount: 0,
    pageCount: 0,
    sortOrder: 0,
    isNew: false,
    showAlert: false,
};

export default function Roles() {
    const [roles, setDataRoles] = useState([]);
    const [selected, setSelected] = useState([]);
    const [role, setRole] = useState('');
    const [dialogAddRole, setDialogAddRole] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [globalFilter1, setGlobalFilter1] = useState('');
    const [loading, setLoading] = useState(false);
    const [rightCheck, setRightChecked] = useState([]);
    const [checkName, setCheckboxName] = useState([]);
    const [rightData, setRightData] = useState({
        visible: false,
        id: null,
        data: []
    });
    const [dataDetail, setDataDetail] = useState({
        visible: false,
        edit: false,
        data: {
            id: 0,
            name: ''
        }
    });
    const onCustomPage = (e) => {
        console.log('onCustomPage invoked!');
        setLoading(true);
        const { rows, page } = e;
        const { pageConfig } = state;
        state.pageConfig = { ...pageConfig, page: page + 1, pageSize: rows };
        getDataRole();
    };
    const paginatorTemplate = {
        layout: "RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink",
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
            ];

            return (
                <React.Fragment>
                    <span className="p-mx-1" style={{ color: "var(--text-color)", userSelect: "none" }}>
                        Items per page:{" "}
                    </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: "var(--text-color)", userSelect: "none", width: "120px", textAlign: "center" }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        },
    };

    let req = -1;
    const onSearchType = (e) => {
        let text = e.target.value;
        onSearch();
    }
    const onSearch = (e) => {
        clearTimeout(req);
        req = setTimeout(() => {
            roleService.getRolesData(
                { FieldName: 'name', value: globalFilter1 },
                (res) => {
                    const { first, pageConfig, rowsCount } = state;
                    state.first = (pageConfig.page - 1) * pageConfig.pageSize;
                    state.rowsCount = res.totalCount;
                    let data = [];
                    res.data.map((v, i) => {
                        data.push({ no: i + 1, id: v.id, name: v.name, createdDate: v.createdDate });
                    })
                    setDataRoles(data);
                }
            )
        }, 250);

    }

    const onSearchPress = e => {
        if(e.code === 'Enter'){
            onSearch();
        }
    }

    const onSort = (e) => {
        console.log(e);
        const { pageConfig } = state;
        pageConfig.sortField = e.sortField;
        if (e.sortField === "createdDate"){
            e.sortField = "created_date";
        }

        state.pageConfig = { ...pageConfig, orderBy: e.sortField, ascending: !pageConfig.ascending };
        console.log(state.pageConfig);
        getDataRole();
    };
    const tableHeader = (
        <div className="table-header">
            <div>
                <span style={{ display: "inline-block", width: 200 }}>List of Roles</span>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilter1} onChange={(e) => setGlobalFilter1(e.target.value)} onKeyPress={onSearchPress} placeholder="Search By Name" style={{ width: 400 }} />
                </span>
                <span style={{ marginLeft: 10 }}>
                    <Button className="p-button p-component p-button-outlined p-button-primary" style={{ width: 100 }} onClick={onSearch}>
                        <span className="p-button-icon p-c p-button-icon-left pi pi-filter"></span>
                        <span className="p-button-label p-c">Filter</span>
                    </Button>
                </span>
            </div>
            <div>
                <span style={{ marginLeft: 10 }}>
                    <Button className="p-button p-component p-button-raised p-button-warning" style={{ color: "#fff" }} onClick={() => setDialogAddRole(true)}>
                        <span className="p-button-icon p-c p-button-icon-left pi pi-plus"></span>
                        <span className="p-button-label p-c">Add Role</span>
                    </Button>
                </span>
            </div>
        </div>
    );

    const openRightData = (data) => {
        let id = data.id;
        roleService.getRolesRight(
            id, (res) => {
                setRightData({ id: id, data: res.data });
                let name = [];
                let check = [];
                res.data.map((v, i) => {
                    name.push(v.menu);
                    if (v.granted == true) {
                        check.push(v.menuId);
                    }
                });
                setRightChecked(check);
                setCheckboxName(name);
            }
        )
    }
    const setRightCheck = (index, id, name, check) => {
        let arr = [rightData.data];
        let array = [];
        arr[0][index] = { menuId: id, name: name, granted: check };
        setRightData({ ...rightData, arr });

        if (check == false) {
            array = rightCheck;
            let pos = array.indexOf(id);
            array.splice(pos, 1);
            setRightChecked(array);
        } else {
            array.push(id);
            setRightChecked([...rightCheck, array[0]]);
        }
        //setRightData({...rightData,data: arr});
    }
    const updateRight = () => {
        let rights = [];
        let data = { menuIds: rightCheck };
        roleService.updateRight(
            data,
            rightData.id,
            (res) => {
                Swal.fire({
                    title: 'Success',
                    text: 'Update role right success',
                    type: 'success',
                    confirmButtonColor: '#FFC107',
                    confirmButtonText: 'Yes!'
                }).then(res => { setDataDetail({ ...dataDetail, edit: false, visible: true }); getDataRole(); });
            }
        )
    }

    //Action For Roles

    const getDataRole = () => {
        roleService.getRolesData(
            state.pageConfig
            , (res) => {
                const { first, pageConfig, rowsCount } = state;
                state.first = (pageConfig.page - 1) * pageConfig.pageSize;
                state.rowsCount = res.totalCount;
                let data = [];
                res.data.map((v, i) => {
                    data.push({ no: i + 1, id: v.id, name: v.name, createdDate: v.createdDate });
                })
                setDataRoles(data);
                setLoading(false);
            });
    }
    const selectItem = (e) => {
        setSelected(e);
        setIsSelected(true);
    }
    const openData = (data) => {
        setDataDetail({
            ...dataDetail, visible: true, data: {
                id: data.id,
                name: data.name
            }
        });
    }
    const addRole = () => {
        if (role !== '') {
            roleService.addRole(
                { name: role },
                (res) => {
                    Swal.fire({
                        title: 'Success',
                        text: 'Add role success',
                        type: 'success',
                        confirmButtonColor: '#FFC107',
                        confirmButtonText: 'Refresh'
                    }).then(res => { setDialogAddRole(false); getDataRole(); setRole('') });
                }
            )
        }


    }
    const updateRole = () => {
        const {name} = dataDetail.data;
        let right = { menuIds: rightCheck };
        if (name.length !== 0) {
            const payload =  {
                role: dataDetail.data,
                right
            };
            roleService.updateRoleAndRight(
                payload,
                (res) => {
                    Swal.fire({
                        title: 'Success',
                        text: 'Update role success',
                        type: 'success',
                        confirmButtonColor: '#FFC107',
                        confirmButtonText: 'Yes!'
                    }).then(res => { setDataDetail({ ...dataDetail, edit: false, visible: false }); getDataRole(); });
                }
            )
        }
    }
    const removeRole = data => {
        Swal.fire({
            title: `Delete Role ${data.name}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            console.log(result);
            if (result.value) {
                roleService.removeRole(
                    data.id,
                    (res) => {
                        getDataRole();
                    }
                )
            }
        })

    }

    const onCancel = status => () => {
        setDialogAddRole(status);
    }

    const closeDetail = () => {
        setDataDetail({ ...dataDetail, visible: false });
    }
    const button = (data, props) => (<>
        <Button onClick={() => { openData(data); openRightData(data) }} style={{ margin: 2, color: '#fff' }} className='p-button p-button-success p-component p-button-raised'>
            <span className="p-button-icon p-c p-button-icon-left pi pi-pencil"></span>
            <span className="p-button-label p-c">Edit</span>
        </Button>
        <Button onClick={() => removeRole(data)} style={{ margin: 2, color: '#fff' }} className="p-button p-component p-button-raised p-button-danger">
            <span className="p-button-icon p-c p-button-icon-left pi pi-trash"></span>
            <span className="p-button-label p-c">Remove</span>
        </Button>
    </>);
    const footer = (
        <div>
            <Button label="Cancel" icon="pi pi-times" className='p-button-text' onClick={() => setDataDetail({ ...dataDetail, visible: false })} />
            <Button className="p-button-info" label="Save Role" icon="pi pi-check" onClick={updateRole} />
        </div>
    );
    const footerAdd = (
        <div>
            <Button label="Cancel" icon="pi pi-times" className='p-button-text' onClick={onCancel(false)} />
            <Button className="p-button-info" label="Save" icon="pi pi-check" onClick={() => addRole()} />
        </div>
    );
    const { pageConfig, first, rowsCount } = state;

    useEffect(() => {
        getDataRole();
    }, []);
    return (
        <>
            <div className="table-demo">
                <div className="card">
                    <h5>Roles List</h5>
                    <div>
                        <DataTable
                            value={roles}
                            rows={state.pageConfig.pageSize}
                            rowsPerPageOptions={[1, 10, 20, 50]}
                            selectionMode="single"
                            selection={selected}
                            onSelectionChange={e => selectItem(e.value)}
                            globalFilter={globalFilter1}
                            loading={loading}
                            header={tableHeader}
                            totalRecords={rowsCount}
                            first={first}
                            sortField={pageConfig.sortField}
                            sortOrder={pageConfig.ascending ? 1 : -1}
                            lazy
                            paginator
                            responsiveLayout="scroll"
                            paginatorTemplate={paginatorTemplate}
                            paginatorClassName="p-jc-end"
                            className="p-datatable-customers"
                            dataKey="id"
                            emptyMessage="No customers found."
                            onPage={onCustomPage}
                            onSort={onSort}
                            responsiveLayout="scroll">
                            <Column field="no" header="No" style={{ width: '10%' }}></Column>
                            <Column field="name" header="Name" style={{ width: '35%' }} sortable></Column>
                            <Column field="createdDate" header="Created At" style={{ width: '35%' }} sortable></Column>
                            <Column header="Action" style={{ width: '30%' }} body={button}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
            <Dialog footer={footer} header="Role Name" visible={dataDetail.visible} onHide={() => setDataDetail({ ...dataDetail, visible: false })} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '50vw' }}>
                <InputText value={dataDetail.data.name} onChange={(e) => setDataDetail({ ...dataDetail, edit: true, data: { id: dataDetail.data.id, name: e.target.value } })} style={{ width: '100%', marginBottom: 10 }} validateOnly={true} />
                {dataDetail.data.name == '' ? <small id="username2-help" className="p-error p-d-block">Username is required.</small> : ''}
                <h5>Role Rights</h5>
                {rightData.data.map((val, index) => (
                    <div className="p-col-12" key={index} style={{ marginTop: 10 }}>
                        <Checkbox
                            inputId={index}
                            value={val.menuId}
                            onChange={(e) => setRightCheck(index, val.menuId, val.menu, e.checked)}
                            checked={val.granted}
                        ></Checkbox>
                        <label htmlFor={index} className="p-checkbox-label" style={{ marginLeft: 10 }}>{checkName[index]}</label>
                    </div>
                ))}
            </Dialog>
            <Dialog footer={footerAdd} header="Role Name" visible={dialogAddRole} onHide={() => setDialogAddRole(false)} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '50vw' }}>
                <InputText value={role} onChange={(e) => setRole(e.target.value)} style={{ width: '100%' }} />
                {role == '' ? <small id="username2-help" className="p-error p-d-block">Username is required.</small> : ''}
            </Dialog>
        </>
    )
}

import React, { useState, useEffect, useRef } from 'react';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProductService } from '../service/ProductService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
 
export const Dashboard = () => {
 
    const history = useHistory();
    
    return (
        <div className="grid">
            <div className="col-12">
                <div className="card mb-0" style={{height: 'calc(100vh - 180px)'}}>
                    <div className="">
                         <h3>Admin Panel Warehouse Management System Lite</h3>
                         <h1 style={{color: '#F5A81C'}}>Welcome Modenese!</h1>
                    </div> 
                </div>
            </div>
        </div>
    );
}

import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { UserService } from "../../service/UserService";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Dialog } from "primereact/dialog";
import { withSwalInstance } from 'sweetalert2-react';
import Swal from 'sweetalert2';
import { UserDetail } from "../UserDetail";
import useForm from "./useForm";
import './../../App.css';

const SweetAlert = withSwalInstance(Swal);

const state = {
    pageConfig: {
        page: 1,
        pageSize: 10,
        orderBy: "id",
        ascending: true,
        sortField: 'id'
    },
    first: 0,
    rowsCount: 0,
    pageCount: 0,
    sortOrder: 0,
    isNew: false,
    showAlert: false,
};

const initValue = {
    id: 0,
    nik: "",
    name: "",
    email: "",
    isActive: true,
    locationCode: "",
    locationName: "",
    role: 4,
    sapId: "",
    whsCode: "",
    whsName: "",
    password: "",
    confirm: "",
    prcCode: "",
    phone: ""
};

export const UserList = () => {
    const { values, handleChange, handleSubmit, setValue, setValues, errors } = useForm(initValue);
    const [users, setUsers] = useState(null);
    const [user, setUser] = useState({});
    const [locations, setLocation] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [prcs, setPrcs] = useState([]);
    const [roles, setRoles] = useState([]);
    const [sapUsers, setSAPUsers] = useState([]);
    const [globalFilter1, setGlobalFilter1] = useState("");
    const [loading, setLoading] = useState(false);
    const [dialog, setDisplayDialog] = useState(false);
    const [locationId, setLocationId] = useState('');
    //const history = useHistory();

    const userService = new UserService();

    useEffect(() => {
        getLocations();
        getWarehouses();
        getPrcs();

        userService.getRolesData({ page: 1, pageSize: 100 }, (res) => {
            const { data } = res;
            setRoles(data);
        });

        userService.getSAPUser({ page: 1, pageSize: 1000 }, (res) => {
            const { data } = res;
            setSAPUsers(data);
        });

        setLoading(true);
        getData();
    }, []);

    const getData = () => {
        userService.getUser(
            state.pageConfig,
            (res) => {
                const { first, pageConfig, rowsCount } = state;
                state.first = (pageConfig.page - 1) * pageConfig.pageSize;
                state.rowsCount = res.totalCount;
                // const { data } = res;
                // data.forEach(item => { item.password = 'xxxxxx'; item.confirm = 'xxxxxx' });
                setUsers(res.data);
                setLoading(false);
            },
            (error) => {
                setLoading(false);
            }
        );
    };

    const onAddNew = () => {
        state.isNew = true;
        setValues(initValue);
        setDisplayDialog(true);
    };

    const statusBodyTemplate = (data) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <InputSwitch checked={data.isActive} />
            </>
        );
    };

    const openDetail = (data) => (e) => {
        state.isNew = false;
        setValues(data);
        setDisplayDialog(true);
        getWarehouses(data.locationCode);
    };

    const onCustomPage = (e) => {
        setLoading(true);
        const { rows, page } = e;
        const { pageConfig } = state;
        state.pageConfig = { ...pageConfig, page: page + 1, pageSize: rows };
        getData();
    };

    const onSort = (e) => {
        const { pageConfig } = state;

        pageConfig.sortField = e.sortField;
        if (e.sortField === "sapId") e.sortField = "sap_Id";
        if (e.sortField === "locationName") e.sortField = "location_name";

        state.pageConfig = { ...pageConfig, orderBy: e.sortField, ascending: !pageConfig.ascending };
        getData();
    };

    const handleChangeWarehouse = e => {
        const value = e.value;
        const warehouse = warehouses.find(x => x.whsCode == value);
        const { whsName, whsCode, location } = warehouse;
        setValues({ ...values, whsName, whsCode, locationCode: location + '' });
        setLocationId(location);
        LocationTemplate();
    }

    const handleChangePrc = e => {
        const value = e.value;
        const prc = prcs.find(x => x.prcCode == value);
        const { prcCode } = prc;
        setValues({ ...values, prcCode });
    }


    const handleChangeLocation = e => {
        const location = locations.find(x => x.code === e.value);
        setValues({ ...values, locationCode: e.value, locationName: location.location == null ? '' : location.location });
        getWarehouses(e.value);
    }

    //Get API Services
    const getLocations = () => {
        userService.getLocation({ page: 1, pageSize: 100 }, (res) => {
            const { data } = res;
            data.forEach((e) => {
                e.code += "";
            });
            setLocation(data);
        });
    }

    const getWarehouses = (id) => {
        userService.getWarehouses({ page: 1, pageSize: 100, FieldName: 'location', value: id }, (res) => {
            const { data } = res;
            setWarehouses(data);
        });
    }

    const getPrcs = () => {
        userService.getPrcs((res) => {
            setPrcs(res);
        });
    }

    const dialogFooter = (
        <>
            <Button type="button" label="Save" onClick={() => setDisplayDialog(false)} icon="pi pi-save" className="p-button-primary" style={{ color: "white", width: 100 }} />
            <Button type="button" label="Cancel" onClick={() => setDisplayDialog(false)} icon="pi pi-times" className="p-button-secondary p-button-outlined" style={{ width: 100 }} />
        </>
    );

    const onSearch = () => {
        userService.getUser(
            { FieldName: 'name', value: globalFilter1 },
            (res) => {
                const { first, pageConfig, rowsCount } = state;
                state.first = (pageConfig.page - 1) * pageConfig.pageSize;
                state.rowsCount = res.totalCount;
                // const { data } = res;
                // data.forEach(item => { item.password = 'xxxxxx'; item.confirm = 'xxxxxx' });
                setUsers(res.data);
                setLoading(false);
            },
            (error) => {
                setLoading(false);
            }
        );
    }

    const onSubmit = (data) => {
        if (state.isNew) {
            userService.postUser(
                values,
                (res) => {
                    Swal.fire({
                        title: 'Success',
                        text: 'Create user success',
                        type: 'success',
                        confirmButtonColor: '#FFC107',
                        confirmButtonText: 'Yes!'
                    }).then(res => { setDisplayDialog(false); getData(); });
                },
                (err) => {
                    console.log("Create user fail");
                }
            );
        } else {
            userService.updateUser(
                values,
                (res) => {
                    Swal.fire({
                        title: 'Success',
                        text: 'Update user success',
                        type: 'success',
                        confirmButtonColor: '#FFC107',
                        confirmButtonText: 'Yes!'
                    }).then(res => { setDisplayDialog(false); getData(); });
                },
                (err) => {
                    console.log("Create user fail");
                }
            );
        }
    };

    const searchOnKeyPress = e => {
        if (e.code === 'Enter') {
            onSearch();
        }
    }

    const actionTemplate = (data, props) => <Button type="button" icon="pi pi-user-edit" className="p-button p-button-success" onClick={openDetail(data)}></Button>;

    const paginatorTemplate = {
        layout: "RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink",
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
            ];

            return (
                <React.Fragment>
                    <span className="p-mx-1" style={{ color: "var(--text-color)", userSelect: "none" }}>
                        Items per page:{" "}
                    </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: "var(--text-color)", userSelect: "none", width: "120px", textAlign: "center" }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        },
    };

    const tableHeader = (
        <div className="table-header">
            <div>
                <span style={{ display: "inline-block", width: 200 }}>List of Users</span>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilter1} onChange={(e) => setGlobalFilter1(e.target.value)} onKeyPress={searchOnKeyPress} placeholder="Global Search" style={{ width: 300 }} />
                </span>
                <span style={{ marginLeft: 10 }}>
                    <Button className="p-button p-component p-button-outlined p-button-primary" style={{ width: 100 }} onClick={onSearch}>
                        <span className="p-button-icon p-c p-button-icon-left pi pi-filter"></span>
                        <span className="p-button-label p-c">Filter</span>
                    </Button>
                </span>
            </div>
            <div>
                <span style={{ marginLeft: 10 }}>
                    <Button className="p-button p-component p-button-raised p-button-warning" style={{ color: "#fff" }} onClick={onAddNew}>
                        <span className="p-button-icon p-c p-button-icon-left pi pi-plus"></span>
                        <span className="p-button-label p-c">Add User</span>
                    </Button>
                </span>
            </div>
        </div>
    );

    const bodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]}
            </>
        );
    };

    const LocationTemplate = () => {
        return (
            <Dropdown
                value={values.locationCode}
                name="locationCode"
                required
                onChange={handleChangeLocation}
                optionValue="code"
                optionLabel="location"
                options={locations}
                placeholder="Select Location">
            </Dropdown>
        )
    }

    const { pageConfig, first, rowsCount } = state;
    return (
        <div className="table-demo">
            <div className="card">
                <h5>User List</h5>
                <DataTable
                    value={users}
                    rows={state.pageConfig.pageSize}
                    globalFilter={globalFilter1}
                    loading={loading}
                    header={tableHeader}
                    totalRecords={rowsCount}
                    first={first}
                    sortField={pageConfig.sortField}
                    sortOrder={pageConfig.ascending ? 1 : -1}
                    lazy
                    rowHover
                    paginator
                    paginatorTemplate={paginatorTemplate}
                    paginatorClassName="p-jc-end"
                    className="p-datatable-customers"
                    dataKey="id"
                    responsiveLayout="scroll"
                    emptyMessage="No customers found."
                    onPage={onCustomPage}
                    onSort={onSort}
                >
                    <Column field="id" header="User ID" sortable></Column>
                    <Column field="name" header="Name" sortable></Column>
                    <Column field="sapId" header="SAP ID" sortable></Column>
                    <Column field="locationName" header="Location" sortable></Column>
                    <Column field="whsName" header="Warehouse" sortable></Column>
                    <Column field="isActive" header="Status" body={statusBodyTemplate}></Column>
                    <Column headerStyle={{ width: "8rem", textAlign: "center" }} bodyStyle={{ textAlign: "center", overflow: "visible", justifyContent: "center" }} body={actionTemplate}></Column>
                </DataTable>
            </div>
            <Dialog header={<h3>User Detail</h3>} visible={dialog} style={{ width: "60vw" }} modal onHide={() => setDisplayDialog(false)}>
                <div style={{ background: "#ccc", height: 1, marginBottom: 20 }}></div>
                <div className="p-fluid">
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        onInvalid={(e) => {
                            console.log("oninvalid", e);
                        }}
                    >
                        <div className="p-grid">
                            <div className="p-col floatlabel-demo">
                                <div className="field grid">
                                    <label htmlFor="id" className="col-12 mb-2 md:col-4 md:mb-2">
                                        User ID
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <InputText value={values.id} readOnly type="text" />
                                    </div>
                                </div>
                                <div className="field grid">
                                    <label htmlFor="nik" className="col-12 mb-2 md:col-4 md:mb-2">
                                        NIK
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <InputText value={values.nik} name="nik" onChange={handleChange} required type="text" />
                                    </div>
                                </div>
                                <div className="field grid">
                                    <label htmlFor="name" className="col-12 mb-2 md:col-4 md:mb-2">
                                        Name
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <InputText value={values.name} name="name" onChange={handleChange} required type="text" />
                                    </div>
                                </div>
                                <div className="field grid">
                                    <label htmlFor="email" className="col-12 mb-2 md:col-4 md:mb-2">
                                        Email
                                    </label>
                                    <div className="col-12 md:col-10 md:mb-2">
                                        <InputText value={values.email} name="email" onChange={handleChange} type="email" required />
                                    </div>
                                </div>
                                <div className="field grid">
                                    <label htmlFor="sapid" className="col-12 mb-2 md:col-4 md:mb-2">
                                        SAP User ID
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <Dropdown value={values.sapId} name="sapId" onChange={handleChange} required optionValue="useR_CODE" optionLabel="useR_CODE" options={sapUsers} placeholder="Select SAP User" filter showClear filterBy="useR_CODE"></Dropdown>
                                    </div>
                                </div>
                                <div className="field grid">
                                    <label htmlFor="role" className="col-12 mb-2 md:col-4 md:mb-2">
                                        Role
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <Dropdown value={values.role} name="role" onChange={handleChange} required optionValue="id" optionLabel="name" options={roles} placeholder="Select Role"></Dropdown>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: "10%" }}></div>
                            <div className="p-col floatlabel-demo">
                                <div className="field grid">
                                    <label htmlFor="password" className="col-12 mb-2 md:col-4 md:mb-2">
                                        Password
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <InputText value={values.password || ''} name="password" onChange={handleChange} type="password" />
                                    </div>
                                </div>
                                <div className="field grid">
                                    <label htmlFor="confirm" className="col-12 mb-2 md:col-4 md:mb-2">
                                        Confirm Password
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <InputText value={values.confirm || ''} name="confirm" onChange={handleChange} type="password" />
                                    </div>
                                </div>
                                <div className="field grid">
                                    <label htmlFor="phone" className="col-12 mb-2 md:col-4 md:mb-2">
                                        Phone Number
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <InputText value={values.phone} name="phone" onChange={handleChange} type="text" />
                                    </div>
                                </div>
                                <div className="field grid">
                                    <label htmlFor="email3" className="col-12 mb-2 md:col-4 md:mb-2">
                                        Cost Center
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <Dropdown options={prcs} value={values.prcCode} name="prcCode" required onChange={handleChangePrc} optionValue="prcCode" optionLabel="prcName" placeholder="Select Cost Center" filter filterBy="prcName"></Dropdown>
                                    </div>
                                </div>
                                <div className="field grid">
                                    <label htmlFor="email3" className="col-12 mb-2 md:col-4 md:mb-2">
                                        Location
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <LocationTemplate />
                                    </div>
                                </div>
                                <div className="field grid">
                                    <label htmlFor="ddlwh" className="col-12 mb-2 md:col-6 md:mb-2">
                                        Default Warehouse
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <Dropdown options={warehouses} value={values.whsCode} name="whsCode" required onChange={handleChangeWarehouse} optionValue="whsCode" optionLabel="whsName" placeholder="Select Warehouse" filter filterBy="whsName"></Dropdown>
                                    </div>
                                </div>
                                <div className="field grid">
                                    <label htmlFor="isActive" className="col-12 mb-2 md:col-4 md:mb-2">
                                        Active
                                    </label>
                                    <div className="col-12 md:col-10">
                                        <InputSwitch id="isActive" name="isActive" onChange={handleChange} checked={values.isActive} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ textAlign: "end" }}>
                            <Button type="submit" label="Save" icon="pi pi-save" className="p-button-primary" style={{ color: "white", width: 100, marginRight: 10 }} />
                            <Button type="button" label="Cancel" onClick={() => setDisplayDialog(false)} icon="pi pi-times" className="p-button-secondary p-button-outlined" style={{ width: 100 }} />
                        </div>
                    </form>
                </div>
            </Dialog>

        </div>
    );
};

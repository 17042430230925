import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import App, { AppState } from "./App";
//import * as serviceWorker from './serviceWorker';
import { HashRouter, BrowserRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import axios from "axios";
import { useHistory } from "react-router-dom";

axios.defaults.baseURL = process.env.REACT_APP_URL;
axios.defaults.headers["Content-Type"] = "application/json";

let accessToken = localStorage.getItem("accessToken");
let refreshToken = localStorage.getItem("refreshToken");
if (accessToken) axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;

//console.log('token in index',{accessToken, refreshToken});

const interceptor = axios.interceptors.response.use(
    (response) => response,
    (error) => {
        // Reject promise if usual error
        if (error.response.status !== 401) {
            console.log("no t401 ", error.response.config);
            return Promise.reject(error);
        }

        /*
         * When response code is 401, try to refresh the token.
         * Eject the interceptor so it doesn't loop in case
         * token refresh causes the 401 response
         */

        // axios.interceptors.response.eject(interceptor);
        refreshToken = localStorage.getItem("refreshToken");
        if (refreshToken && !error.response.config._retry) {
            error.response.config._retry = true;
            return axios
                .get("Account/refresh", { params: { refreshToken } })
                .then((res) => {
                    const { acessToken, refreshToken } = res.data.data;
                    localStorage.setItem("accessToken", acessToken);
                    localStorage.setItem("refreshToken", refreshToken);
                    axios.defaults.headers["Authorization"] = `Bearer ${acessToken}`;
                    error.response.config.headers["Authorization"] = "Bearer " + acessToken;
                    console.log("error.response.config", error.response.config);
                    return axios(error.response.config);
                })
                .catch((err) => {
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("refreshToken");
                    window.location.reload();
                    console.log("err.response.config", err.response.config);
                    return Promise.reject(err);
                })
                .finally(() => {
                    console.log("finally interceptor invoked!");
                });
        }
    }
);

ReactDOM.render(
    <BrowserRouter>
        <ScrollToTop>
            <App></App>
        </ScrollToTop>
    </BrowserRouter>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();

import axios from 'axios'

export class AuthService {

    login = (payload, onSuccess, onError) => {
        return axios.post("Account/login", payload).then(res => { if (onSuccess) { onSuccess(res.data); } }).catch(e => { if (onError) onError(e); });
    }

    getUserInfo = (onSuccess, onError) => {
        return axios.get('Account/self').then(res => { if (onSuccess) { onSuccess(res.data); } }).catch(e => { if (onError) onError(e); });
    }

    logout = (onSuccess, onError) => {
        return axios.post("Account/logout").then(res => { if (onSuccess) { onSuccess(res.data); } }).catch(e => { if (onError) onError(e); });
    }
}

import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { UserService } from "../service/UserService";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Dialog } from "primereact/dialog";
import { withSwalInstance } from 'sweetalert2-react';
import Swal from 'sweetalert2';
import { UserDetail } from "./UserDetail";
import useForm from "./Users/useForm";
import './../App.css';

const state = {
    pageConfig: {
        page: 1,
        pageSize: 10
    },
    first: 0,
    rowsCount: 0,
    pageCount: 0,
    sortOrder: 0,
    isNew: false,
    showAlert: false,
};

export const SessionList = () => {
    const [sessions, setSessions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [globalFilter1, setGlobalFilter1] = useState("");
    const [selected, setSelected] = useState([]);
    const [dialog, setDisplayDialog] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [sessionSap, setSessionSap] = useState('');

    const userService = new UserService();

    const getSessions = () => {
        setLoading(true);
        userService.getSessions(
            state.pageConfig,
            (res) => {
                const { first, pageConfig, rowsCount } = state;
                state.first = (pageConfig.page - 1) * pageConfig.pageSize;
                state.rowsCount = res.data.length;
                let data = [];
                res.data.map((v, i) => {
                    data.push({ no: i + 1, name: v });
                })
                setSessions(data);
                setLoading(false);
            }, (err) => {
                console.log(err);
            })
    }

    const selectItem = (e) => {
        setSelected(e);
        setIsSelected(true);
    }

    const onSort = (e) => {
        console.log(e);
        const { pageConfig } = state;

        state.pageConfig = { ...pageConfig, orderBy: e.sortField, ascending: !pageConfig.ascending };
        getSessions();
    };

    const paginatorTemplate = {
        layout: "RowsPerPageDropdown CurrentPageReport FirstPageLink PrevPageLink NextPageLink LastPageLink",
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
            ];

            return (
                <React.Fragment>
                    <span className="p-mx-1" style={{ color: "var(--text-color)", userSelect: "none" }}>
                        Items per page:{" "}
                    </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: "var(--text-color)", userSelect: "none", width: "120px", textAlign: "center" }}>
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        },
    };

    /*const onSort = (e) => {
        const { pageConfig } = state;

        pageConfig.sortField = e.sortField;
        if (e.sortField === "data") e.sortField = "data";

        state.pageConfig = { ...pageConfig, orderBy: e.sortField, ascending: !pageConfig.ascending };
        getSessions();
    };*/

    const onCustomPage = (e) => {
        setLoading(true);
        const { rows, page } = e;
        const { pageConfig } = state;
        state.pageConfig = { ...pageConfig, page: page + 1, pageSize: rows };
        getSessions();
    };

    const statusBodyTemplate = (data) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <InputSwitch checked={data.isActive} />
            </>
        );
    };

    const onDelete = (data) => {
        Swal.fire({
            title: `Confirmation Action`,
            text: `Delete Access Token ${data.name}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            console.log(result.value);
            setLoading(true);
            if (result.value) {
                userService.removeSession(
                    data.name,
                    (res) => {
                        setTimeout(() => {
                            getSessions();
                        }, 2000);
                    },
                    (error) => {
                        console.log(error);
                    }
                )
            }
        })
    }

    const actionTemplate = (data, props) => (
        <>
            <Button onClick={(e) => onDelete(data)} style={{ margin: 2, color: '#fff' }} className="p-button p-component p-button-raised p-button-danger">
                <span className="p-button-icon p-c p-button-icon-left pi pi-trash"></span>
                <span className="p-button-label p-c">Remove</span>
            </Button>
        </>
    );

    const { pageConfig, first, rowsCount } = state;

    const tableHeader = (
        <div className="table-header">
            <div>
                <span style={{ display: "inline-block", width: 200 }}>List of Active Sessions</span>
            </div>
        </div>
    );

    useEffect(() => {
        getSessions();
        userService.getSessionSap((res) => {
            setSessionSap(res);
        });
    }, []);

    const generateSession = async () => {
        await userService.generateSessionSap((res) => {
            setSessionSap(res.data.sessionId);
            Swal.fire({
                title: 'Success',
                text: 'Generate session success',
                type: 'success',
            });
        });
    }

    return (
        <>
            <div className="table-demo">
                <div className="card">
                    <h5>Sessions List</h5>

                    <DataTable
                        value={sessions}
                        rows={state.pageConfig.pageSize}
                        rowsPerPageOptions={[1, 10, 20, 50]}
                        selectionMode="single"
                        globalFilter={globalFilter1}
                        loading={loading}
                        header={tableHeader}
                        totalRecords={rowsCount}
                        first={first}
                        sortField={pageConfig.sortField}
                        sortOrder={pageConfig.ascending ? 1 : -1}
                        lazy
                        paginator
                        responsiveLayout="scroll"
                        paginatorTemplate={paginatorTemplate}
                        paginatorClassName="p-jc-end"
                        className="p-datatable-customers"
                        dataKey="id"
                        emptyMessage="No customers found."
                        onPage={onCustomPage}
                        onSort={onSort}>
                        <Column field="no" header="#"></Column>
                        <Column field="name" header="Active Sessions"></Column>
                        <Column headerStyle={{ width: "8rem", textAlign: "center" }} bodyStyle={{ textAlign: "center", overflow: "visible", justifyContent: "center" }} body={actionTemplate}></Column>
                    </DataTable>
                </div>
            </div>

            <div className="card">
                <h5>SAP Sessions</h5>
                <table>
                    <tbody>
                        {
                            sessionSap
                                ? <tr style={{ color: "limegreen", fontWeight: "bold" }}>
                                    <td>Valid Session ID</td>
                                    <td>:</td>
                                    <td>{sessionSap.substring(0, 13)}-xxxx-xxxx-xxxxxxxxxxx</td>
                                </tr>
                                :
                                <tr style={{ color: "red", fontWeight: "bold" }}>
                                    <td>Invalid Session ID</td>
                                    <td>:</td>
                                    <td>-</td>
                                </tr>
                        }
                    </tbody>
                </table>

                <Button disabled={sessionSap} onClick={generateSession} style={{ margin: 2, color: '#fff' }} className="p-button p-component p-button-raised p-button-success">
                    <span className="p-button-icon p-c p-button-icon-left pi pi-refresh"></span>
                    <span className="p-button-label p-c">Generate Session</span>
                </Button>
            </div>
        </>
    )
}

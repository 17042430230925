import React from 'react'

export const UserDetail = () => {
    return (
        <div className="grid">
            <div className="col-12">
                <div className="card mb-0" style={{height: 'calc(100vh - 180px)'}}>
                    <div>
                         <h3>User Detail</h3>
                         <h1 style={{color: '#F5A81C'}}>Welcome Modenese!</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

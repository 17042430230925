import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { OverlayPanel } from 'primereact/overlaypanel';
import { AppState } from './App';
import { AuthService } from './service/AuthService';
import axios from 'axios';

const service = new AuthService();

export const AppTopbar = (props) => {
    const [data, setData] = useState(true);
    const op = useRef(null);
    const onToggleOverlay = e => {
        op.current.toggle(e);
    }

    const onSignOut = () => {
        service.logout();
        AppState.isLoggedIn = false;
        localStorage.clear();
        delete axios.defaults.headers['Authorization'];
        setData(true);
        props.onSignOut();
    }

    return (
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
                <img src={props.layoutColorMode === 'light' ? 'assets/logo.png' : 'assets/logo.png'} alt="logo" />
                <span className="app-name">WMS Lite</span>
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars" />
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>
            <ul className={classNames("layout-topbar-menu lg:flex origin-top", { 'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                <li className="p-d-none">
                    <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                        <i className="pi pi-calendar" />
                        <span>Events</span>
                    </button>
                </li>
                <li className="p-d-none">
                    <button className="p-link layout-topbar-button" >
                        <i className="pi pi-cog" />
                        <span>Settings</span>
                    </button>
                </li>
                <li>
                    <button className="p-link layout-topbar-button" onClick={onToggleOverlay}>
                        <i className="pi pi-user" />
                        <span>Profile</span>
                    </button>
                </li>
            </ul>
            <OverlayPanel ref={op} appendTo={document.body} style={{ width: 240 }}>
                <div style={{ display: 'flex', alignItems: 'center', height: 50 }}>
                    <img src="https://www.femina.co.id/images/images/IMG-7279.jpg" alt="" style={{ width: 50, height: 50, borderRadius: '50%' }} />
                    <span style={{ display: 'inline-block', color: '#161616', fontSize: 18, marginLeft: 20 }}>{AppState.userInfo?.name}</span>

                </div>
                <div style={{ height: 1, background: '#ccc', marginTop: 10 }}></div>
                <div style={{ marginTop: 10 }}>
                    <button className="p-button p-component p-button-danger p-button-text" style={{ width: 200 }} onClick={onSignOut}>
                        <span className="pi pi-sign-out"></span>
                        <span className="p-button-label p-c">Log Out</span>
                        <span className="p-ink"></span>
                    </button>
                </div>
            </OverlayPanel>
        </div>
    );
}

import axios from "axios";

export class UserService {
    getUser = (params, onSuccess, onError) => {
        return axios
            .get("User", { params })
            .then((res) => {
                if (onSuccess) {
                    onSuccess(res.data);
                }
            })
            .catch((e) => {
                if (onError) onError(e);
            });
    };

    getUserById = (id, onSuccess, onError) => {
        return axios
            .get("User/" + id)
            .then((res) => {
                if (onSuccess) {
                    onSuccess(res.data);
                }
            })
            .catch((e) => {
                if (onError) onError(e);
            });
    };

    getLocation = (params, onSuccess, onError) => {
        return axios
            .get("SapData/location", { params })
            .then((res) => {
                if (onSuccess) {
                    onSuccess(res.data);
                }
            })
            .catch((e) => {
                if (onError) onError(e);
            });
    };

    getPrcs = (onSuccess, onError) => {
        return axios
            .get("Prc")
            .then((res) => {
                if (onSuccess) {
                    onSuccess(res.data);
                }
            })
            .catch((e) => {
                if (onError) onError(e);
            });
    };

    getWarehouses = (params, onSuccess, onError) => {
        return axios
            .get("SapData/warehouse", { params })
            .then((res) => {
                if (onSuccess) {
                    onSuccess(res.data);
                }
            })
            .catch((e) => {
                if (onError) onError(e);
            });
    };

    getRoles = (params, onSuccess, onError) => {
        return axios
            .get("SapData/location", { params })
            .then((res) => {
                if (onSuccess) {
                    onSuccess({
                        data: [
                            { id: 1, name: "Administrator" },
                            { id: 2, name: "Pusat" },
                            { id: 3, name: "Cabang" },
                        ],
                    });
                }
            })
            .catch((e) => {
                if (onError) onError(e);
            });
    };

    getRolesData = (params, onSuccess, onError) => {
        return axios.get('Role', { params })
            .then((res) => {
                if (onSuccess) {
                    onSuccess(res.data);
                }
            }).catch((e) => {
                if (onError) onError(e);
            });
    }

    getRolesRight = (param, onSuccess, onError) => {
        return axios.get(`Role/right/${param}`)
            .then((res) => {
                if (onSuccess) {
                    onSuccess(res.data);
                }
            }).catch(e => {
                onError(e);
            });
    }

    getSAPUser = (params, onSuccess, onError) => {
        return axios
            .get("SapData/user-sap", { params })
            .then((res) => {
                if (onSuccess) {
                    const { data } = res.data;
                    onSuccess({ data });
                }
            })
            .catch((e) => {
                if (onError) onError(e);
            });
    };

    getRoleRights = (params, onSuccess, onError) => {
        return axios
            .get(`Role/right/${params.id}`)
            .then((res) => {
                if (onSuccess) {
                    const { data } = res.data;
                    onSuccess({ data })
                }
            }).catch((e) => {
                if (onError) onError(e);
            })
    }

    getSessions = (params, onSuccess, onError) => {
        return axios
            .get(`Session/user-session`)
            .then((res) => {
                if (onSuccess) {
                    const { data } = res.data;
                    onSuccess({ data });
                }
            }).catch((e) => {
                if (onError) onError(e);
            })
    }

    getSessionSap = (onSuccess, onError) => {
        return axios
            .get(`Session/sap`)
            .then((res) => {
                if (onSuccess) {
                    onSuccess(res.data);
                }
            }).catch((e) => {
                if (onError) onError(e);
            })
    }

    generateSessionSap = (onSuccess, onError) => {
        return axios
            .post("Account/sl-login")
            .then((res) => {
                if (onSuccess) {
                    onSuccess(res.data);
                }
            }).catch((e) => {
                if (onError) onError(e);
            })
    }

    postUser = (payload, onSuccess, onError) => {
        return axios
            .post("User", payload)
            .then((res) => {
                if (onSuccess) {
                    const { data } = res.data;
                    onSuccess(data);
                }
            })
            .catch((e) => {
                if (onError) onError(e);
            });
    };

    updateUser = (payload, onSuccess, onError) => {
        return axios
            .put(`User/${payload.id}`, payload)
            .then((res) => {
                if (onSuccess) {
                    const { data } = res.data;
                    onSuccess(data);
                }
            })
            .catch((e) => {
                if (onError) onError(e);
            });
    };

    addRole = (payload, onSuccess, onError) => {
        return axios
            .post(`Role`, payload)
            .then((res) => {
                if (onSuccess) {
                    const { data } = res.data;
                    onSuccess(data);
                }
            })
            .catch((e) => {
                if (onError) onError(e);
            });
    }

    updateRole = (payload, onSuccess, onError) => {
        return axios
            .put(`Role/${payload.id}`, payload)
            .then((res) => {
                if (onSuccess) {
                    const { data } = res.data;
                    onSuccess(data);
                }
            }).catch((e) => {
                if (onError) onError(e);
            })
    }

    updateRight = (payload, id, onSuccess, onError) => {
        return axios
            .put(`Role/right/${id}`, payload)
            .then((res) => {
                if (onSuccess) onSuccess(res.data);
            }).catch((e) => {
                if (onError) onError(e);
            })
    }

    removeRole = (param, onSuccess, onError) => {
        return axios
            .delete(`Role/${param}`)
            .then((res) => {
                if (onSuccess) onSuccess(res.data);
            })
            .catch((e) => {
                if (onError) onError(e);
            })
    }

    removeSession = (param, onSuccess, onError) => {
        return axios
            .delete(`Session/user-session/${param}`)
            .then((res) => {
                if (onSuccess) onSuccess(res);
            })
            .catch((err) => {
                if (err) onError(err);
            })
    }

    updateRoleAndRight = (payload, onSuccess, onError) => {
/*
        {
            payload: {
                role: { id: 0, name: 'string'},
                right: {
                    menuIds: [0,1,2]
                }
            }
        }
*/      console.log('payload', payload);
        const { role, right } = payload;
        axios.put(`Role/${role.id}`, role)
            .then((res) => {
                axios.put(`Role/right/${role.id}`, right).then(r => {
                    if (onSuccess) {
                        onSuccess(r.data);
                    }
                }).catch((e) => {
                    if (onError) onError(e);
                });
            }).catch((e) => {
                if (onError) onError(e);
            })
    }

}
